<template>
  <div class="card">
    <h1 class="tw-text-xl tw-font-bold tw-mb-8">Create Salon</h1>
    <div>
      <Form @submit="submit" :init-schedule="initSchedules" :is-create="true" />
    </div>
  </div>
</template>

<script setup>
import Form from "./Form";
import { ref } from "vue";
import { useSalonForm } from "@/composables/salon";
import { useRouter } from "vue-router";

const { create, postOperationalSchedules } = useSalonForm();
const router = useRouter();

const initSchedules = ref({
  id: 0,
  salon_id: 0,
  monday_close_time: "",
  monday_open: false,
  monday_open_time: "",
  tuesday_close_time: "",
  tuesday_open: false,
  tuesday_open_time: "",
  wednesday_close_time: "",
  wednesday_open: false,
  wednesday_open_time: "",
  thursday_close_time: "",
  thursday_open: false,
  thursday_open_time: "",
  friday_close_time: "",
  friday_open: false,
  friday_open_time: "",
  saturday_close_time: "",
  saturday_open: false,
  saturday_open_time: "",
  sunday_close_time: "",
  sunday_open: false,
  sunday_open_time: "",
});

const submit = async (formData, schedule) => {
  let salonID = await create(formData);
  schedule.salon_id = salonID
  await postOperationalSchedules(schedule);
  await router.push({ name: "salons" });
};
</script>